/*
 * These are leftover styles from the Mantine transition. These seem to be somewhat load bearing global styles that we
 * should remove at some point, as it is extra bytes, but I'm okay leaving them for now.
 */
@layer mantine {

  body,
  html {
    height: 100%;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  body {
    -webkit-font-smoothing: var(--mantine-webkit-font-smoothing);
    -moz-osx-font-smoothing: var(--mantine-moz-font-smoothing);
    background-color: var(--mantine-color-body);
    color: var(--mantine-color-text);
    font-family: var(--mantine-font-family);
    font-size: var(--mantine-font-size-md);
    line-height: var(--mantine-line-height);
    margin: 0;
  }

  @media screen and (max-device-width: 500px) {
    body {
      -webkit-text-size-adjust: 100%;
    }
  }

  :root {
    --mantine-scale: 1;
    --mantine-cursor-type: default;
    --mantine-webkit-font-smoothing: antialiased;
    --mantine-color-scheme: light dark;
    --mantine-moz-font-smoothing: grayscale;
    --mantine-color-white: #fff;
    --mantine-color-black: #000;
    --mantine-line-height: 1.55;
    --mantine-heading-font-weight: 700;
    --mantine-radius-default: calc(0.25rem * var(--mantine-scale));
    --mantine-primary-color-filled: var(--mantine-color-blue-filled);
    --mantine-primary-color-filled-hover: var(--mantine-color-blue-filled-hover);
    --mantine-primary-color-light: var(--mantine-color-blue-light);
    --mantine-primary-color-light-hover: var(--mantine-color-blue-light-hover);
    --mantine-primary-color-light-color: var(--mantine-color-blue-light-color);
    --mantine-breakpoint-xs: 36em;
    --mantine-breakpoint-sm: 48em;
    --mantine-breakpoint-md: 62em;
    --mantine-breakpoint-lg: 75em;
    --mantine-breakpoint-xl: 88em;
    --mantine-spacing-xs: calc(0.625rem * var(--mantine-scale));
    --mantine-spacing-sm: calc(0.75rem * var(--mantine-scale));
    --mantine-spacing-md: calc(1rem * var(--mantine-scale));
    --mantine-spacing-lg: calc(1.25rem * var(--mantine-scale));
    --mantine-spacing-xl: calc(2rem * var(--mantine-scale));
    --mantine-font-size-xs: calc(0.75rem * var(--mantine-scale));
    --mantine-font-size-sm: calc(0.875rem * var(--mantine-scale));
    --mantine-font-size-md: calc(1rem * var(--mantine-scale));
    --mantine-font-size-lg: calc(1.125rem * var(--mantine-scale));
    --mantine-font-size-xl: calc(1.25rem * var(--mantine-scale));
    --mantine-line-height-xs: 1.4;
    --mantine-line-height-sm: 1.45;
    --mantine-line-height-md: 1.55;
    --mantine-line-height-lg: 1.6;
    --mantine-line-height-xl: 1.65;
    --mantine-shadow-xs: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
      0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1);
    --mantine-shadow-sm: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)),
      rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
    --mantine-shadow-md: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)),
      rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
    --mantine-shadow-lg: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)),
      rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale));
    --mantine-shadow-xl: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)),
      rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale));
    --mantine-radius-xs: calc(0.125rem * var(--mantine-scale));
    --mantine-radius-sm: calc(0.25rem * var(--mantine-scale));
    --mantine-radius-md: calc(0.5rem * var(--mantine-scale));
    --mantine-radius-lg: calc(1rem * var(--mantine-scale));
    --mantine-radius-xl: calc(2rem * var(--mantine-scale));

    --mantine-color-dark-filled: #373a40;
    --mantine-color-dark-filled-hover: #2c2e33;
    --mantine-color-dark-light: rgba(55, 58, 64, 0.1);
    --mantine-color-dark-light-hover: rgba(55, 58, 64, 0.12);
    --mantine-color-dark-light-color: #373a40;
    --mantine-color-dark-outline: #373a40;
    --mantine-color-dark-outline-hover: rgba(55, 58, 64, 0.05);
    --mantine-color-gray-filled: #ced4da;
    --mantine-color-gray-filled-hover: #adb5bd;
    --mantine-color-gray-light: rgba(206, 212, 218, 0.1);
    --mantine-color-gray-light-hover: rgba(206, 212, 218, 0.12);
    --mantine-color-gray-light-color: #ced4da;
    --mantine-color-gray-outline: #ced4da;
    --mantine-color-gray-outline-hover: rgba(206, 212, 218, 0.05);
    --mantine-color-red-filled: #ff8787;
    --mantine-color-red-filled-hover: #ff6b6b;
    --mantine-color-red-light: rgba(255, 135, 135, 0.1);
    --mantine-color-red-light-hover: rgba(255, 135, 135, 0.12);
    --mantine-color-red-light-color: #ff8787;
    --mantine-color-red-outline: #ff8787;
    --mantine-color-red-outline-hover: rgba(255, 135, 135, 0.05);
    --mantine-color-pink-filled: #f783ac;
    --mantine-color-pink-filled-hover: #f06595;
    --mantine-color-pink-light: rgba(247, 131, 172, 0.1);
    --mantine-color-pink-light-hover: rgba(247, 131, 172, 0.12);
    --mantine-color-pink-light-color: #f783ac;
    --mantine-color-pink-outline: #f783ac;
    --mantine-color-pink-outline-hover: rgba(247, 131, 172, 0.05);
    --mantine-color-grape-filled: #da77f2;
    --mantine-color-grape-filled-hover: #cc5de8;
    --mantine-color-grape-light: rgba(218, 119, 242, 0.1);
    --mantine-color-grape-light-hover: rgba(218, 119, 242, 0.12);
    --mantine-color-grape-light-color: #da77f2;
    --mantine-color-grape-outline: #da77f2;
    --mantine-color-grape-outline-hover: rgba(218, 119, 242, 0.05);
    --mantine-color-violet-filled: #9775fa;
    --mantine-color-violet-filled-hover: #845ef7;
    --mantine-color-violet-light: rgba(151, 117, 250, 0.1);
    --mantine-color-violet-light-hover: rgba(151, 117, 250, 0.12);
    --mantine-color-violet-light-color: #9775fa;
    --mantine-color-violet-outline: #9775fa;
    --mantine-color-violet-outline-hover: rgba(151, 117, 250, 0.05);
    --mantine-color-indigo-filled: #748ffc;
    --mantine-color-indigo-filled-hover: #5c7cfa;
    --mantine-color-indigo-light: rgba(116, 143, 252, 0.1);
    --mantine-color-indigo-light-hover: rgba(116, 143, 252, 0.12);
    --mantine-color-indigo-light-color: #748ffc;
    --mantine-color-indigo-outline: #748ffc;
    --mantine-color-indigo-outline-hover: rgba(116, 143, 252, 0.05);
    --mantine-color-blue-filled: #4dabf7;
    --mantine-color-blue-filled-hover: #339af0;
    --mantine-color-blue-light: rgba(77, 171, 247, 0.1);
    --mantine-color-blue-light-hover: rgba(77, 171, 247, 0.12);
    --mantine-color-blue-light-color: #4dabf7;
    --mantine-color-blue-outline: #4dabf7;
    --mantine-color-blue-outline-hover: rgba(77, 171, 247, 0.05);
    --mantine-color-cyan-filled: #3bc9db;
    --mantine-color-cyan-filled-hover: #22b8cf;
    --mantine-color-cyan-light: rgba(59, 201, 219, 0.1);
    --mantine-color-cyan-light-hover: rgba(59, 201, 219, 0.12);
    --mantine-color-cyan-light-color: #3bc9db;
    --mantine-color-cyan-outline: #3bc9db;
    --mantine-color-cyan-outline-hover: rgba(59, 201, 219, 0.05);
    --mantine-color-teal-filled: #38d9a9;
    --mantine-color-teal-filled-hover: #20c997;
    --mantine-color-teal-light: rgba(56, 217, 169, 0.1);
    --mantine-color-teal-light-hover: rgba(56, 217, 169, 0.12);
    --mantine-color-teal-light-color: #38d9a9;
    --mantine-color-teal-outline: #38d9a9;
    --mantine-color-teal-outline-hover: rgba(56, 217, 169, 0.05);
    --mantine-color-green-filled: #69db7c;
    --mantine-color-green-filled-hover: #51cf66;
    --mantine-color-green-light: rgba(105, 219, 124, 0.1);
    --mantine-color-green-light-hover: rgba(105, 219, 124, 0.12);
    --mantine-color-green-light-color: #69db7c;
    --mantine-color-green-outline: #69db7c;
    --mantine-color-green-outline-hover: rgba(105, 219, 124, 0.05);
    --mantine-color-lime-filled: #a9e34b;
    --mantine-color-lime-filled-hover: #94d82d;
    --mantine-color-lime-light: rgba(169, 227, 75, 0.1);
    --mantine-color-lime-light-hover: rgba(169, 227, 75, 0.12);
    --mantine-color-lime-light-color: #a9e34b;
    --mantine-color-lime-outline: #a9e34b;
    --mantine-color-lime-outline-hover: rgba(169, 227, 75, 0.05);
    --mantine-color-yellow-filled: #ffd43b;
    --mantine-color-yellow-filled-hover: #fcc419;
    --mantine-color-yellow-light: rgba(255, 212, 59, 0.1);
    --mantine-color-yellow-light-hover: rgba(255, 212, 59, 0.12);
    --mantine-color-yellow-light-color: #ffd43b;
    --mantine-color-yellow-outline: #ffd43b;
    --mantine-color-yellow-outline-hover: rgba(255, 212, 59, 0.05);
    --mantine-color-orange-filled: #ffa94d;
    --mantine-color-orange-filled-hover: #ff922b;
    --mantine-color-orange-light: rgba(255, 169, 77, 0.1);
    --mantine-color-orange-light-hover: rgba(255, 169, 77, 0.12);
    --mantine-color-orange-light-color: #ffa94d;
    --mantine-color-orange-outline: #ffa94d;
    --mantine-color-orange-outline-hover: rgba(255, 169, 77, 0.05);

    --mantine-color-brand-0: #ffffff;
    --mantine-color-brand-1: #ffffec;
    --mantine-color-brand-2: #ffffb7;
    --mantine-color-brand-3: #e8fdca;
    --mantine-color-brand-4: #b4fa50;
    --mantine-color-brand-5: #80c70f;
    --mantine-color-brand-6: #4b9500;
    --mantine-color-brand-7: #106700;
    --mantine-color-brand-8: #003a00;
    --mantine-color-brand-9: #001a00;
    --mantine-color-anchor: #b4fa50;
    --mantine-color-brand-filled: #b4fa50;
    --mantine-color-brand-filled-hover: #80c70f;
    --mantine-color-brand-light: rgba(180, 250, 80, 0.1);
    --mantine-color-brand-light-hover: rgba(180, 250, 80, 0.12);
    --mantine-color-brand-light-color: #b4fa50;
    --mantine-color-brand-outline: #b4fa50;
    --mantine-color-brand-outline-hover: rgba(180, 250, 80, 0.05);
    --mantine-primary-color-filled: var(--mantine-color-brand-filled);
    --mantine-primary-color-filled-hover: var(--mantine-color-brand-filled-hover);
    --mantine-primary-color-light: var(--mantine-color-brand-light);
    --mantine-primary-color-light-hover: var(--mantine-color-brand-light-hover);
    --mantine-primary-color-light-color: var(--mantine-color-brand-light-color);
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

